.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 42%;
  min-width: 384px;
  padding: 1.1rem;
  margin: 1.5rem 0;
  color: var(--gray-500);

  @media (max-width: 1024px) {
    width: unset;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: unset;
  }
}

.auth-form h4 {
  font-weight: lighter;
  text-align: center;
  color: white;
  margin: 0.5rem 0;
}

.logo {
  align-self: center;
  margin-bottom: 0.6rem;
}

.form-inputs {
  display: grid;
  gap: 0.6rem;
}

.disclaimer-link {
  margin-top: 1rem;
  text-align: center;
  color: white;
  text-decoration: underline;
}
