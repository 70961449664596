.styles_container__RuiLu {
  display: flex;
}

.styles_container__RuiLu > * {
  flex: 1 1;
  height: 100vh;
}

.styles_container-auth-image__1G7OM {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.styles_container-form__ZwXrz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.styles_txt-powered-by__rZdiK {
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--gray-300);
}

.styles_container__N96d4 {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.styles_container__N96d4 label {
  font-weight: lighter;
  text-align: left;
  margin-bottom: 0.25rem;
  color: var(--white);
}

.styles_with-icon__RiAiS {
  padding-left: 3rem;

  @media (max-width: 768px) {
    padding-left: 1rem;
  }
}

.styles_btn-toggle-password-visibility__6gFw1 {
  position: absolute;
  align-self: flex-end;
  top: 16px;
  right: 16px;
}

.styles_btn-toggle-password-visibility__6gFw1:hover {
  cursor: pointer;
  opacity: 0.8;
}

.styles_btn-toggle-password-visibility__6gFw1:active {
  opacity: 0.6;
}

.styles_icon__mrOrW {
  position: absolute;
  left: 0.9rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.styles_text-error__Ciwaa {
  font-size: small;
  text-align: left;
  margin-top: 2px;
  color: var(--danger);
}

@media (max-width: 768px) {
  .styles_container__N96d4 input {
    width: auto !important;
  }

  .styles_icon__mrOrW {
    display: none;
  }
}

/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/560bcc4e09749527-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d77e36fa1e563bf0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/51fbb2aa5ef2b5cf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7da2a3ff16027414-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2cffe11a47028c14-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4cf1eda6528c4545-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/037c6ceced78c87a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1520da2fce335b3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/84c22514fc6f1667-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5ea4559b0835633-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8bbb723f6276338f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Bai_Jamjuree_8e04b5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/996798fe8aee0dc3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Bai_Jamjuree_Fallback_8e04b5';src: local("Arial");ascent-override: 96.17%;descent-override: 24.04%;line-gap-override: 0.00%;size-adjust: 103.99%
}.__className_8e04b5 {font-family: '__Bai_Jamjuree_8e04b5', '__Bai_Jamjuree_Fallback_8e04b5';font-style: normal
}

.styles_container__qz7_H {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.styles_container__qz7_H:not(.styles_border__Ipi9w) input[type="checkbox"] {
  border: 1px solid var(--white);
  border-radius: 7px;
}

.styles_container__qz7_H label {
  font-weight: lighter;
  font-size: 1.05rem;
}

.styles_container__qz7_H input {
  margin: 0;
}

.styles_border__Ipi9w {
  border: 1px solid var(--white);
  border-radius: 3px;
}

.styles_text-error__EXBks {
  font-size: small;
  text-align: left;
  color: var(--danger);
}

.styles_auth-form__1ncLZ {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 42%;
  min-width: 384px;
  padding: 1.1rem;
  margin: 1.5rem 0;
  color: var(--gray-500);

  @media (max-width: 1024px) {
    width: unset;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: unset;
  }
}

.styles_auth-form__1ncLZ h4 {
  font-weight: lighter;
  text-align: center;
  color: white;
  margin: 0.5rem 0;
}

.styles_logo__e8Rlu {
  align-self: center;
  margin-bottom: 0.6rem;
}

.styles_form-inputs__8Am86 {
  display: grid;
  grid-gap: 0.6rem;
  gap: 0.6rem;
}

.styles_disclaimer-link__jl3GL {
  margin-top: 1rem;
  text-align: center;
  color: white;
  text-decoration: underline;
}

